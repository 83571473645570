// React component imports
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import ButtonAddChild from "../../components/ButtonMobile/ButtonAddChild";
import ButtonEdit from "../../components/ButtonMobile/ButtonEdit";
import ButtonLogout from "../../components/ButtonMobile/ButtonLogout";
import ButtonMobile from "../../components/ButtonMobile/ButtonMobile"; //Join the class button
import FormModal from "../../components/Modal/FormModal";
import {AddUserMemberForm} from "../../components/Form/Form";
import { AuthContext } from "../../context/authContext";
import axios from "axios";
import api from '../../Api'; // adjust the path based on where api.js is located
import { Link, useLocation, useNavigate } from "react-router-dom";

import {  LoadingOverlay,
  LoadingSpinner,
} from "../../globalStyles.js";

export const MyProfile = () => {

  const { currentUser, loading, logout } = useContext(AuthContext);

const [data, setData] = useState([]);
      	const [modalOpen, setModalOpen] = useState(false);
	const [modalMessage, setModalMessage] = useState("");
  const [formData, setFormData] = useState(null);
    const [errors, setErrors] = useState({});

    const [loadingCircle, setLoadingCircle] = useState(false);
      const [updateData, setUpdateData] = useState(false);


useEffect(() => {
  const initializeForm = () => {
    // Initialize student form fields based on setting
    
   setFormData( { firstname: "",
    lastname: "",
    dob: "",
    gender: ""})
    }


  initializeForm();


}, [updateData]); 

console.log(formData)
  
  const handleClose = () => {
   setModalOpen(false);
};


  useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await api.get(
					`/classBooking/api/user/getUserMembers`,{
            withCredentials: true,
        }
				);

				// let uniqueArray = [...new Set(res.data.map((item) => item.category))]; // Remove duplicates

				// let newtabValues = [
				// 	{ name: "tab_1", label: "All Events" },
				// 	...uniqueArray.map((item, index) => ({
				// 		name: `tab_${index + 2}`, // Start from tab_2
				// 		label: item,
				// 	})),
				// ];
				setData(res.data);
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
		// setResultChange("All");

		// const intervalId = setInterval(fetchData, 1000);

		// Clean up the interval when the component unmounts or when dependencies change
		// return () => {
		// 	clearInterval(intervalId);
		// };
	}, [updateData]);


    const handleSubmit = async() => {
  setLoadingCircle(true);

try {
				const res = await api.post(
					`/classBooking/api/user/addUserMember`,
					formData,{   withCredentials: true,}
				);

        if (res.status === 200) {
          // setModalMessage("User Member Added");
          setModalOpen(false);
            setLoadingCircle(false);
            setUpdateData(!updateData); // Toggle the value of updateData
        }

  }	catch (err) {
				console.log(err);
			}
}


const getInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : '';
  };



  return (
    <>
      <CardContent>
            {currentUser ?( 
              <>
<AccountWrapper>

   <ImageWrapper>
                <CircleImage>
        {getInitial(currentUser.name)}
      </CircleImage>
                </ImageWrapper>

<AccountDetails>
<NameTitle>{currentUser.name}</NameTitle>
<DetailValue>{currentUser.mobile}</DetailValue>
</AccountDetails>
</AccountWrapper>

<Subheader>My members</Subheader>

{data.map((info, index) => {

    const dateOfBirth = new Date(info.dateofbirth);
    const formattedDateOfBirth = dateOfBirth.toLocaleDateString();

    return(
    <ClassInfoWrapper>
          <Label> ST{info.id}</Label> 
          <ClassTitle>
       {info.first_name} {info.last_name}
          </ClassTitle>

     <ClassDetailsStyled>
          <DetailsGrid>
      <DetailLabel>  Date of birth </DetailLabel>
         <DetailValue>  {formattedDateOfBirth}
</DetailValue>
</DetailsGrid>
         <DetailsGrid>
   <DetailLabel> Gender </DetailLabel>
<DetailValue>{info.gender}</DetailValue>
</DetailsGrid>

  </ClassDetailsStyled>
         
        </ClassInfoWrapper>
)
})}       


  <ButtonContainer>
  <ButtonAddChild
 onClick={() => setModalOpen(true)}
 status="uploadedMessage"
 />
  {/* <ButtonEdit
 onClick={() => console.log("Button clicked")}
   status="uploadedMessage"
     /> */}
   <ButtonLogout
         onClick={logout}
        status="uploadedMessage"
       />
 </ButtonContainer>
      
        </> ):      <ButtonContainer>	<Link to="/en/login"><ButtonMobile>Login</ButtonMobile></Link>
 </ButtonContainer>}
       
      </CardContent>

      {modalOpen && (
				<FormModal
        title = "Add User Member"
					isOpen={modalOpen}
					autoClose={false}
          handleClose={handleClose}

				>

{formData && ( <AddUserMemberForm formData={formData} setFormData={setFormData} withSubmitBtn={true} language ={"en"} errors={errors} handleSubmit={handleSubmit}/>)}



 </FormModal>
			)}

 {/*************************************** 
            // LOADING CIRCLE //
        ***************************************/}
            {loadingCircle && (
              <LoadingOverlay>
                <LoadingSpinner />
              </LoadingOverlay>
            )}



    </>
  );
};

// export const MyProfile = () => {
//   const classData = [
//     {
//       details: [
//         {
//           label: "NAME",
//           value: "Ben Chan",
//         },
//         {
//           label: "CONTACT",
//           value: "99990000",
//         },
//         { label: "EMAIL", value: "hello@hello.com" },
//         { imagePath: "/assets/ChanBen.jpg" },
//       ],
//     },
//     {
//       details: [
//         {
//           label: "CHILD",
//           value: "Peter Chan",
//         },
//         {
//           label: "BIRTH",
//           value: "08/08/2015",
//         },
//         { label: "STUDENT ID", value: "004567" },
//         { imagePath: "/assets/ChanPeter.jpg" },
//       ],
//     },
//   ];

//   return (
//     <>
//       {classData.map((classInfo, index) => (
//         <ClassCard key={index} classInfo={classInfo} />
//       ))}

//     
//     </>
//   );
// };
export default MyProfile;

// Styled-components for layout
export const CardContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  padding-bottom: 20px;
`;

// export const ClassInfoWrapper = styled.div`
//   display: flex;
//   justify-content: center;
//   background-color: rgba(255, 255, 255, 0.5);
//   border: 2px solid #fff;
//   border-radius: 0 0 20px 20px;
//   width: 95%;
// `;

export const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr; /* Adjusted column widths */
  grid-template-rows: repeat(3, auto); /* Keep the three rows */
  grid-template-areas:
    "label1 value1 image"
    "label2 value2 image"
    "label3 value3 image";
  gap: 10px;
  width: 100%;
  align-items: start;
`;

export const DetailLabel = styled.span`
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #0c729e;
`;

export const DetailValue = styled.span`
  font-family: Lora, sans-serif;
  color: #0c729e;
  font-weight: 500;
  font-size: 16px;
  // text-decoration: underline;
  font-style: italic;
`;

// export const CircleImage = styled.img`
//   border: solid #0c729e 3px;
//   width: 90px;
//   height: 90px;
//   border-radius: 50%;
//   object-fit: cover;
//   box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
// `;

// New styled component for wrapping the image
const ImageWrapper = styled.div`
flex:1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensure the wrapper takes full height of the grid area */
  grid-area: image; /* Assign the grid area directly in the styled component */
`;

const ButtonContainer = styled.div`
margin-top:20px;
width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Adjust the gap between buttons as needed */
`;



const AccountWrapper = styled.div`
padding:20px 0px;
width:100%;
display:flex;
align-items:flex-start;
justify-content:flex-start;
background-color:#ffffff;




`

const AccountDetails = styled.div`
flex:3;
display:flex;
flex-direction:column;
align-items:flex-start;
justify-content:flex-start;
`

export const NameTitle = styled.div`
  font: 700 28px Poppins, sans-serif;
  text-align: left; // Center text
  margin-top: 10px;
  // margin-bottom: 10px; // Adjust spacing after title
`;


export const Subheader = styled.div`
  font-weight: 700;
  font-size: 20px;
  // text-align: left; // Center text
  margin-top: 10px; // Adjust spacing after venue
  margin-bottom:20px;
  // display:flex;
  // align-items:center;
  // justify-content:flex-start;
  border-left:10px solid rgb(0,0,139);
  width:100%;
  color:black;
  padding-left:20px;
  

`;


export const ClassInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffffff;
  width:100%;
  padding:10px 10px;
  // border-radius: 0 0 20px 20px;
  border-bottom:15px solid lightgrey;
`;

export const Label = styled.div`
  font: 15px Lora, sans-serif;
  text-align: center; // Center text
  // margin-bottom: 20px; // Adjust spacing after venue
  // border:1px solid red;
  background-color:lightgrey;
  color:black;
  border-radius:2px;
  padding:2px 5px;

`;

export const OptionListPrice = styled.div`
	// width: 100%;
	// margin: 10px auto;
  text-align:left;
  font-size:20px;
  font-weight:800;
`;


export const ClassTitle = styled.h1`
  // border: solid black 1px;
  // background-color: #fff;
  max-width: 400px;
  height: auto;
  font: 700 18px Poppins, sans-serif;
  text-align: left; // Center text
  margin: 10px 0;
  // padding: 0 20px;
`;

export const ClassDetailsStyled = styled.div`
  // display: grid;
  // grid-template-columns: 1fr 3fr; // Defines two columns: 1st column is 1/4th, 2nd column is 3/4ths of the total width
  // gap: 10px; // Gap between grid items
  width: 100%; //Makes the grid 80% of the width of the parent container
  padding-bottom: 10px;
`;

export const CircleImage = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: white;
`;