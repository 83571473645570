// src/components/Header.js
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import axios from "axios";
import api from '../../Api'; // adjust the path based on where api.js is located


const StyledHeader = styled.header`
  background-image: linear-gradient(to bottom, #2f3e5d 50%, #41506f);
  padding: 1.5rem;
  text-align: center;
  @media (min-width: 500px) {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoIconHolder = styled.div`
  background-color: white;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid black;
`;

const LogoIcon = styled.img`
  height: 50%;
  width: 50%;
  object-fit: fill;
`;

const UrgentMessage = styled.div`
  color: #fff;
  flex-grow: 1;
  font-size: 30px;
  text-align: center;
`;

const LanguageIcon = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

function Header() {
  const [isChinese, setIsChinese] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();


  const toggleLanguage = () => {
     let newPath = location.pathname.replace('/en/', '/ch/');
  if (location.pathname.includes('/ch/')) {
    newPath = location.pathname.replace('/ch/', '/en/');
  }

    // Append the current search parameters to the new path
  newPath += location.search;

  navigate(newPath);
  setIsChinese(!isChinese);
};

const host = window.location.hostname;
const subdomain = host.split('.')[0];
const shopName = subdomain;


const [shopLogo, setShopLogo] = useState(null);



useEffect(() => {
 

  // Replace with your actual API call
 	const fetchData = async () => {
			try {
				const res = await api.get(
					`/classBooking/api/shop/`,{
            withCredentials: true,
        }
				);
				setShopLogo(res.data.logo);
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
}, [shopName]);
  

  return (
    <StyledHeader>
      <HeaderContent>
        {/* <Link to="/"> */}
          <LogoIconHolder>
            <Link to={`/en/`}>
            <LogoIcon
              loading="lazy"
              alt="LogoIcon"
              src={shopLogo}
            />
            </Link>
          </LogoIconHolder>
        {/* </Link> */}
        <UrgentMessage>{/* Placeholder for urgent message */}</UrgentMessage>
        {/* <LanguageIcon
          loading="lazy"
          alt="LanguageIcon"
          src="/assets/languageIcon.svg"
          onClick={toggleLanguage} // Toggle language and navigate
        /> */}
      </HeaderContent>
    </StyledHeader>
  );
}

export default Header;
