import React, { useState } from "react";
import styled from "styled-components";
import { Success, Fail } from "../../assets/IconSVG";
import DisclaimerConsentCheckBox from "../Form/DisclaimerConsentCheckbox";

// Styled checkbox container
const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20 0px;
`;

const IconContainer = styled.div`
  flex: 1;

  svg {
    width: 100px;
  }
`;

// Styled label for better accessibility and design
const TextContainer = styled.div`
  font-size: 14px;
  flex: 2;
`;

export const SuccessSubmission = ({ language }) => {
  return (
    <>
      <MessageContainer>
        <IconContainer>
          <Success />
        </IconContainer>

        {language === "en" ? (
          <TextContainer>
            Thank you for your booking! Please check your email for booking
            confirmation
          </TextContainer>
        ) : (
          <TextContainer>
            感謝您的預訂！請檢查您的電子郵件以獲取預訂確認。
          </TextContainer>
        )}
      </MessageContainer>
    </>
  );
};

export const FailSubmission = ({ language }) => {
  return (
    <>
      <MessageContainer>
        <IconContainer>
          <Fail />
        </IconContainer>

        {language === "en" ? (
          <TextContainer>The booking failed. Please try again.</TextContainer>
        ) : (
          <TextContainer>預訂失敗。請再試一次。</TextContainer>
        )}
      </MessageContainer>
    </>
  );
};

export const Disclaimer = ({
  language,
  formData,
  setFormData,
  modalOpen,
  setModalOpen,
  content,
}) => {
  return (
    <>
      <MessageContainer>
        <DisclaimerConsentCheckBox
          formData={formData}
          setFormData={setFormData}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          content={content}
          language={language}
        />
      </MessageContainer>
    </>
  );
};
