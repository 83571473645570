import React, { useState, useEffect, useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datetime/css/react-datetime.css";
import "react-datepicker/dist/react-datepicker.css";
import { Calender } from "../../assets/IconSVG.js";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";

import {
  LogintemplateRoot,
  HeaderTitle,
  InputForm,
  InputBoxWrapper,
  InputBox,
  Message,
  TitleContainer,
  FormContainer,
  InputContainer,
  Input,
  Label,
  FormLabel,
  SubmitButton,
  FlexWrapper,
  DisclaimerWrapper,
  FormFeedback,
  GenderContainer,
  GenderRadio,
  FormDateContainer,
  StyledDatePicker,
  InputDateContainer,
} from "../../pages/loginpages/LoginStyles";

import ButtonMobile from "../ButtonMobile/ButtonMobile";

export const AddUserMemberForm = ({
  formData,
  setFormData,
  setStudent,
  handleSubmit,
  withSubmitBtn,
  errors,
  language,
}) => {
  // const [value, setValue] =
  //   (React.useState < Dayjs) | (null > dayjs("2022-04-17"));

  console.log(formData);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = {};

    if (!formData.firstname) {
      errors.firstname = "Firstname is required";
    }
    if (!formData.lastname) {
      errors.lastname = "Lastname is required";
    }

    if (!formData.dob) {
      errors.dob = "Date of birth is required";
    } else {
      const dob = new Date(formData.dob);
      const today = new Date();
      const minDate = new Date("1920-01-01");

      if (dob < minDate || dob > today) {
        errors.dob = "Date of birth must be between 1900 and today";
      }
    }

    if (!formData.gender) {
      errors.gender = "Gender is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Valid form if no errors/ return true or false
  };

  const handleDateChange = (value) => {
    const formattedDate = dayjs(value.$d).format("YYYY-MM-DD");

    setFormData((prevFormData) => ({
      ...prevFormData,
      dob: formattedDate,
    }));
  };

  const handleClick = () => {
    console.log("click to validate");
    const isValid = validateForm();
    console.log(isValid);
    if (isValid) {
      console.log("valid..can add user");
      handleSubmit();
    }
  };

  return (
    <>
      {language === "en" ? (
        <FormContainer
          onSubmit={(e) => {
            e.preventDefault();
            handleClick();
          }}
        >
          <Input
            required
            type="text"
            placeholder="First Name"
            name="firstname"
            value={formData?.firstname}
            onChange={handleChange}
          />
          {(formErrors.firstname || errors.firstname) && (
            <FormFeedback>
              {formErrors.firstname || errors.firstname}
            </FormFeedback>
          )}
          <Input
            required
            type="text"
            placeholder="Last Name"
            name="lastname"
            value={formData?.lastname}
            onChange={handleChange}
          />
          {(formErrors.lastname || errors.lastname) && (
            <FormFeedback>
              {formErrors.lastname || errors.lastname}
            </FormFeedback>
          )}
          <FormDateContainer>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateField"]}>
                <DateField
                  label="Date of Birth"
                  name="dob"
                  onChange={handleDateChange}
                />
              </DemoContainer>
            </LocalizationProvider>
          </FormDateContainer>
          {(formErrors.dob || errors.dob) && (
            <FormFeedback>{formErrors.dob || errors.dob}</FormFeedback>
          )}
          <GenderContainer>
            <FormLabel>Gender:</FormLabel>
            <GenderRadio>
              <Input
                type="radio"
                name="gender"
                value="male"
                onChange={handleChange}
              />
              Male
            </GenderRadio>
            <GenderRadio>
              <Input
                type="radio"
                name="gender"
                value="female"
                onChange={handleChange}
              />
              Female
            </GenderRadio>
          </GenderContainer>
          {(formErrors.gender || errors.gender) && (
            <FormFeedback>{formErrors.gender || errors.gender}</FormFeedback>
          )}
          {/* {formErrors.duedate && <FormFeedback>{formErrors.duedate}</FormFeedback>} */}
          {withSubmitBtn && (
            <ButtonMobile onClick={handleClick}>Add</ButtonMobile>
          )}
        </FormContainer>
      ) : (
        <FormContainer
          onSubmit={(e) => {
            e.preventDefault();
            handleClick();
          }}
        >
          <Input
            required
            type="text"
            placeholder="名"
            name="firstname"
            value={formData?.firstname}
            onChange={handleChange}
          />
          {(formErrors.firstname || errors.firstname) && (
            <FormFeedback>
              {formErrors.firstname || errors.firstname}
            </FormFeedback>
          )}
          <Input
            required
            type="text"
            placeholder="姓"
            name="lastname"
            value={formData?.lastname}
            onChange={handleChange}
          />
          {(formErrors.lastname || errors.lastname) && (
            <FormFeedback>
              {formErrors.lastname || errors.lastname}
            </FormFeedback>
          )}
          <FormDateContainer>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateField"]}>
                <DateField
                  label="出生日期"
                  name="dob"
                  onChange={handleDateChange}
                />
              </DemoContainer>
            </LocalizationProvider>
          </FormDateContainer>
          {(formErrors.dob || errors.dob) && (
            <FormFeedback>{formErrors.dob || errors.dob}</FormFeedback>
          )}
          <GenderContainer>
            <FormLabel>性別:</FormLabel>
            <GenderRadio>
              <Input
                type="radio"
                name="gender"
                value="male"
                onChange={handleChange}
              />
              男
            </GenderRadio>
            <GenderRadio>
              <Input
                type="radio"
                name="gender"
                value="female"
                onChange={handleChange}
              />
              女
            </GenderRadio>
          </GenderContainer>
          {(formErrors.gender || errors.gender) && (
            <FormFeedback>{formErrors.gender || errors.gender}</FormFeedback>
          )}
          {/* {formErrors.duedate && <FormFeedback>{formErrors.duedate}</FormFeedback>} */}
          {withSubmitBtn && (
            <ButtonMobile onClick={handleClick}>提交</ButtonMobile>
          )}
        </FormContainer>
      )}
    </>
  );
};
