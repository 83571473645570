import {
  LogintemplateRoot,
  HeaderTitle,
  InputForm,
  InputBoxWrapper,
  InputBox,
} from "./LoginStyles";
/************************************************ */
// RICK CHU added code for mobile
import MobileInput from "./MobileInput"; // Import the new MobileInput component

import ButtonMobile from "../../components/ButtonMobile/ButtonMobile";
import ButtonBack from "../../components/ButtonMobile/ButtonBack";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import api from "../../Api"; // adjust the path based on where api.js is located

import { FormFeedback } from "./LoginStyles";

const ResetPassword = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    mobile: "",
    password: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const mobileRegex = /^\d{8}$/;

  const validateForm = () => {
    const errors = {};

    if (!formData.mobile) {
      errors.mobile = "Mobile is required";
    } else if (!mobileRegex.test(formData.mobile)) {
      errors.mobile =
        "Mobile number has to be in 8 digits long and only numbers";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Valid form if no errors/ return true or false
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  /************************************************ */
  // RICK CHU added code for mobile
  const handleMobileChange = (mobileArray) => {
    // Convert the array of digits to a string and update formData.mobile
    const mobileString = mobileArray.join("");
    setFormData((prevFormData) => ({
      ...prevFormData,
      mobile: mobileString,
    }));
  };

  const handleSubmit = async (e) => {
    const isValid = validateForm();

    if (isValid) {
      try {
        const res = await api.post(
          "/classBooking/api/auth/forgetpassword",
          formData,
          {
            withCredentials: true,
          }
        );

        if (res.status !== 200) {
          setFormErrors(res.data);
        } else {
          navigate(`/en/signup/verify/${res.data}`);
        }
      } catch (err) {
        console.log(err.response.data);
        setFormErrors(err.response.data);
        console.log(formErrors);
      }
    } else {
      console.log("Form is invalid! Not submitting.", formErrors);
    }
  };

  return (
    <LogintemplateRoot>
      <ButtonBack loading="lazy" alt="ArrowBack" onClick={() => navigate(-1)} />
      <HeaderTitle>Reset Password</HeaderTitle>
      <InputForm>
        {/* ************************************************
		// RICK CHU added code for mobile */}
        <MobileInput
          mobileValue={formData.mobile} // Pass the current mobile value
          onMobileChange={handleMobileChange} // Pass the handler to update mobile
        />
        {formErrors.mobile && <FormFeedback>{formErrors.mobile}</FormFeedback>}

        {/*  OLD CODE FOR MOBILE REMOVED *****************	*/}
        {/* <InputBoxWrapper>
          <InputBox
            required
            type="phone"
            placeholder="Enter your registered mobile number"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
          />
        </InputBoxWrapper>
        {formErrors.mobile && <FormFeedback>{formErrors.mobile}</FormFeedback>} */}

        <ButtonMobile onClick={handleSubmit}>Submit</ButtonMobile>
      </InputForm>
    </LogintemplateRoot>
  );
};

export default ResetPassword;
