import React, { useEffect, useRef } from "react";
import { useButton } from "react-aria";
import { useAnimation } from "framer-motion";
import styled from "styled-components";
import { motion } from "framer-motion";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 90px;
  padding: 0 10px;
  border-radius: 10px;
`;

export const TitleMessage = styled.h1`
  font: 400 18px Lora, sans-serif;
  text-align: center;
  margin-bottom: 0px;
  color: #0c729e;
  font-style: italic;
  padding: 0 10px;
`;

export const MergedButtonWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 50%;
  height: 50px;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #657f80; // Corrected background color syntax
`;

export const AddIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM16.25 12.75H12.75V16.25C12.75 16.6645 12.414 17 12 17C11.586 17 11.25 16.6645 11.25 16.25V12.75H7.75C7.336 12.75 7 12.4145 7 12C7 11.5855 7.336 11.25 7.75 11.25H11.25V7.75C11.25 7.3355 11.586 7 12 7C12.414 7 12.75 7.3355 12.75 7.75V11.25H16.25C16.664 11.25 17 11.5855 17 12C17 12.4145 16.664 12.75 16.25 12.75Z"
      fill="#637394"
    />
  </svg>
);

const ButtonAddChild = ({ onClick, status }) => {
  let controls = useAnimation();
  let ref = useRef();

  useEffect(() => {
    controls.start({ backgroundColor: "#fff" }); // Ensure initial background color matches the corrected styled component
  }, [controls]);

  let { buttonProps } = useButton(
    {
      onPressStart: () => {
        controls.stop();
        controls.set({ backgroundColor: "#91b5b7" });
      },
      onPressEnd: () => {
        controls.start({
          backgroundColor: "#fff",
          transition: { duration: 0.65 },
        });
      },
      onPress: onClick,
    },
    ref
  );

  const messages = {
    uploadedMessage: ["Add Member"], //ENGLISH
    otherMessage: ["上传付款证明照片"], //CHINESE
  };

  const children = messages[status] || []; // Default to an empty array if status is unknown

  return (
    <MergedButtonWrapper animate={controls} {...buttonProps}>
      <AddIcon />
      {children.map((message, index) => (
        <TitleMessage key={index}>{message}</TitleMessage>
      ))}
    </MergedButtonWrapper>
  );
};

export default ButtonAddChild;