import React from "react";
import styled from "styled-components";

const MobileInput = ({ mobileValue, onMobileChange }) => {
  // Convert the mobile string into an array of digits for rendering
  const mobileArray = mobileValue
    .split("")
    .concat(Array(8).fill(""))
    .slice(0, 8);

  const handleMobileChange = (e, index) => {
    const newMobileArray = [...mobileArray];
    newMobileArray[index] = e.target.value.replace(/[^0-9]/g, ""); // Allow only digits
    onMobileChange(newMobileArray);

    // Auto-focus the next input if a digit is entered
    if (e.target.value && index < 7) {
      document.getElementById(`mobile-${index + 1}`).focus();
    }
  };

  const handleMobileKeyDown = (e, index) => {
    if (e.key === "Backspace" && !mobileArray[index] && index > 0) {
      document.getElementById(`mobile-${index - 1}`).focus();
    }
  };

  return (
    <MobileInputContainer>
      <MobileInputTitle>Enter Hong Kong Mobile (+852)</MobileInputTitle>
      <MobileInputWrapper>
        {Array.from({ length: 8 }).map((_, index) => (
          <ThinInput
            required
            key={index}
            id={`mobile-${index}`}
            type="tel"
            maxLength="1"
            value={mobileArray[index]}
            onChange={(e) => handleMobileChange(e, index)}
            onKeyDown={(e) => handleMobileKeyDown(e, index)}
            pattern="[0-9]*"
          />
        ))}
      </MobileInputWrapper>
    </MobileInputContainer>
  );
};

export default MobileInput;

const MobileInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const MobileInputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
  gap: 6px;
`;

const MobileInputTitle = styled.div`
  text-align: center;
  font-size: clamp(1.8rem, 2vw, 2rem);
  color: #6b7180;
  margin-bottom: 1rem;
  width: 100%;
`;

const ThinInput = styled.input`
  width: calc(100% / 8);
  min-width: 30px;
  height: 60px;
  text-align: center;
  font-size: 1.8rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;

  &:focus {
    border-color: #77dffb;
  }

  @media (max-width: 400px) {
    width: 12%;
    min-width: 25px;
    height: 48px;
    font-size: 1.6rem;
  }
`;
