import styled from "styled-components";
import DatePicker from "react-datepicker";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import "react-datepicker/dist/react-datepicker.css";

export const LogintemplateRoot = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-6xl) 30px 100%;
  box-sizing: border-box;
  gap: 20px;
  line-height: normal;
  letter-spacing: normal;
`;

export const ArrowBack = styled.img`
  width: 30px;
  height: 30px;
  position: relative;
  cursor: pointer;
`;

export const HeaderTitle = styled.div`
  font: 700 32px var(--font-poppins), sans-serif; //ENGLISH
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px var(--padding-xl); // Use the same padding on both sides

  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  color: #0c729e;
  flex-shrink: 0;
`;

export const HeaderTitleC = styled.div`
  font: 700 32px var(--font-noto-sans-tc), sans-serif; //CHINESE
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px var(--padding-xl); // Use the same padding on both sides
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  color: #0c729e;
  flex-shrink: 0;
`;

export const InputForm = styled.form`
  /* border: solid red 3px; */
  margin: 0;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const InputBoxWrapper = styled.div`
  align-self: stretch;
  border-radius: var(--br-12xl-5);
  background-color: var(--color-whitesmoke);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px var(--padding-mini) 0px var(--padding-base);
`;

export const InputBox = styled.input`
  height: 63px;
  flex: 1;
  position: relative;
  font-size: var(--font-size-lg);
  font-size: var(--font-size-lg);
  font-family: var(--font-poppins);
  color: var(--color-gray);
  text-align: left;
  display: flex;
  align-items: center;
  z-index: 1;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0 var(--padding-mini);
  border-radius: var(--br-12xl-5);
  &:focus {
    outline: none;
  }
`;

export const Message = styled.div`
  flex: 1;
  position: relative;
  font-weight: 500;
  font-size: var(--font-size-sm);
  cursor: pointer;
`;

/*********************************/
//  FORM STYLES   //
/*********************************/

export const Body = styled.div`
  height: 100vh;
  background-color: black;
  overflow: hidden;
`;

export const ContentContainer = styled.div`
  flex-direction: column;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  // padding-bottom: 100px;
  // border: 1px solid red;
  width: 100%;
  max-width: 650px;
  // min-width: 390px;
  margin: auto;
  position: relative;
  top: 5%;

  // @media (max-width: 480px) {
  // 	width: 90vw;
  // }
`;

export const LogoContainer = styled.div`
  width: 100%;
  // border: 1px solid red;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

export const Logo = styled.img`
  height: 100%;
  @media (max-width: 480px) {
    height: 50%;
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  // border: 1px solid red;
  font-size: 1.5rem;
  color: #000000;
  text-align: center;
  font-weight: 400;
`;

export const FormContainer = styled.form`
  // border: 1px solid red;
  text-align: justify;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
  padding: 0 10px;
  /* @media (max-width: 480px) {
    width: 100%;
  } */
`;

export const ContentFlexWrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

//ALBUM NAME INPUT
export const InputContainerText = styled.div`
  input {
    font-family: sans-serif;
    text-align: center;
    color: #abb2b9;
    font-size: 2rem;
    width: 24rem;
    height: 3rem;
    border: 1px solid #abb2b9;
    border-radius: 8px;

    @media (max-width: 480px) {
      width: 18rem;
      height: 3;
      font-size: 18px;
    }
  }
  /* border: 2px solid green; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  gap: 10px;
`;

//DATE & TIME INPUT VALUES
export const InputContainer = styled.div`
  // border: 2px solid green;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  width: 100%;
  gap: 5px;
  position: relative;
  // border:1px solid red;
`;

export const InputHorizontalContainer = styled.div`
  //  border: 2px solid green;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 5px;
`;
export const GroupInputContainer = styled.div`
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column; // if you want the items to be stacked vertically
  width: 100%;
  // position:relative;
  // margin-bottom:10px;
`;

export const Input = styled.input`
  font-family: sans-serif;
  color: #abb2b9;
  text-align: left;
  font-size: 15px;
  width: 100%;
  height: 50px;
  border: 1px solid #abb2b9;
  border-radius: 4px;
  padding: 3px 10px;
  text-decoration: none;
`;

export const TextArea = styled.textarea`
  font-family: sans-serif;
  color: #abb2b9;
  text-align: left;
  font-size: 15px;
  width: 100%;
  height: 20vh; // Make the height responsive
  border: 1px solid #abb2b9;
  border-radius: 4px;
  padding: 3px 10px;
  text-decoration: none;
  resize: vertical; // Allow vertical resizing
`;

export const CodeInput = styled.input`
  font-family: sans-serif;
  color: #abb2b9;
  text-align: center;
  font-size: 20px;
  width: 100%;
  height: 50px;
  border: 1px solid #abb2b9;
  border-radius: 4px;
  padding: 3px 10px;
  text-decoration: none;
`;

export const SubmitButton = styled.button`
  padding: 3px;
  border: none;
  background-color: ${(props) => {
    switch (props.action) {
      case "approve":
        return "green";
      case "cancel":
        return "red";
      case "pending":
        return "orange";
      case "noshow":
        return "grey";
      default:
        return "teal";
    }
  }};
  color: white;
  cursor: pointer;
  width: 100%;
  border-radius: 4px;
  height: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  svg {
    width: 15px;
    fill: white;
  }
`;

export const FormLabel = styled.div`
  font-size: 15px;
  display: flex;
  text-align: left;
  margin-bottom: 10px;
  font-family: sans-serif;
  color: #000000;
`;

export const Label = styled.label`
  font-size: 1.3rem;
  color: #515a5a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const DateContainer = styled.div`
  font-size: clamp(2.2rem, 5vw, 3rem);
  color: #17202a;
  font-family: sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TimeContainer = styled.div`
  font-size: clamp(2.2rem, 5vw, 3rem);
  color: #17202a;
  font-family: sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FlexWrapper = styled.div`
  //border: 1px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  color: white;
  margin: auto;
`;

export const DisclaimerWrapper = styled.div`
  text-align: center;
  // border: 1px solid red;
  width: 90%;
  color: #888888;
  margin: auto;
  font-size: 10px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%; // Or any specific value you want
`;

export const ButtonCreateWrapper = styled.div`
  width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const FormFeedback = styled.div`
  width: 100%;
  color: orange;
  text-align: left;
`;

export const GenderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const GenderRadio = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 15px; /* Adjust the font size */
  padding: 10px; /* Increase the padding for better touch sensitivity */

  input[type="radio"] {
    -webkit-appearance: none; /* Removes default browser styling */
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    border-radius: 50%;
    outline: none;
    box-shadow: 0 0 5px #9ecaed;
    margin-right: 10px; /* Add more space between the radio button and the label text */
  }

  input[type="radio"]:checked {
    background: #9ecaed;
  }
`;

/*********************************/
// Date & Time Picker //
/*********************************/

//DATE & TIME INPUT VALUES
export const InputDateContainer = styled.div`
  input {
    font-family: sans-serif;
    color: #abb2b9;
    text-align: center;
    font-size: 2rem;
    width: 250px;
    height: 3rem;
    border: 1px solid #abb2b9;
    border-radius: 8px;
  }
  /* border: 2px solid green; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  gap: 10px;
`;

export const FormDateContainer = styled.div`
  position: relative;
  // border:1px solid red;
  width: 100%;

  .react-datepicker__header {
    // background-color: red !important;
  }

  .react-datepicker {
    color: #000;
    display: block;
    position: absolute;
    // top: 0;
    right: 50%;
    // left: 0;
    margin: auto;
    background-color: #ffffff !important;
  }
`;

export const DueDateContainer = styled.div`
  width: 100%;
  // position: relative;

  .react-datepicker__header {
    // background-color: red !important;
  }

  .react-datepicker {
    color: #000;
    display: block;
    position: absolute;
    // top: 0;
    right: -200px;
    // left: 0;
    // margin: auto;
    // z-index: 99999999999999999 !important;
    // background-color: red !important;
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  position: relative;
`;

export const StyledDateTime = styled(DateTime)``;
