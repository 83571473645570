import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CSSTransition } from 'react-transition-group';
import { createGlobalStyle } from 'styled-components';
import Cookies from 'js-cookie';


export const SelectClassWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-top: 16px;
`;

export const StyledLessonSelector = styled.div`
  display: flex;
  max-width: 500px;
  flex-direction: column;

  .selector-title {
    border: solid #0c729e 1px;
    font: 700 20px Poppins, sans-serif;
    align-items: center;
    color: #0c729e;
    justify-content: center;
    text-align: center;
    margin-bottom: 8px;
  }

  .lesson-options {
    display: flex;
    flex-direction: column;
    gap: 0px;
    margin-top: 0px;
  }
`;


// export const OptionListContainer = styled.div`
// 	display: flex;
//   flex-direction: column;
//   align-items: flex-start;
// 	width: 100%;
// 	// grid-gap: 5px;
// 	margin: 10px auto;
// 	// border: 1px solid red;
// `;

export const OptionListTitle = styled.div`
	width: 100%;
	margin: 10px auto;
  text-align:left;
  font-size:20px;
  font-weight:600;
`;

export const OptionListPrice = styled.div`
	width: 100%;
	margin: 10px auto;
  text-align:left;
  font-size:20px;
  font-weight:800;
`;



export const CSSTransitionCSS = createGlobalStyle`
  .slide-right-enter {
  transform: translateX(100%);
}

.slide-right-enter-active {
  transform: translateX(0);
  transition: transform 500ms;
}

.slide-right-exit {
  transform: translateX(0);
}

.slide-right-exit-active {
  transform: translateX(-100%);
  transition: transform 500ms;
}

.slide-left-enter {
  transform: translateX(-100%);
}

.slide-left-enter-active {
  transform: translateX(0);
  transition: transform 500ms;
}

.slide-left-exit {
  transform: translateX(0);
}

.slide-left-exit-active {
  transform: translateX(100%);
  transition: transform 500ms;
}
`;

// export const OptionList = styled.div`
// 	width: 100%;
// 	border: 1px solid #abb2b9;
// 	border-radius: 10px;
// 	padding: 3px 10px;
// 	height: 200px;
// 	text-align: center;
// 	display: flex;
// 	flex-direction: row;
// 	justify-content: space-between;
// 	align-items: center;
// 	transition: all 0.2s ease-in-out;
// 	cursor: pointer;
// 	// border: 1px solid red;
// 	background-color: ${(props) => (props.selected ? "#465258" : "#ffffff")};
// 	color: ${(props) => (props.selected ? "#ffffff" : "#000000")};
// `;

export const OptionListTopContainer = styled.div`
	flex: 2;
	display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:flex-start;
	// border: 1px solid blue;
`;

export const OptionListMiddleContainer = styled.div`
color:grey;
font-size:15px;
text-align:left;
	// border: 1px solid blue;
`;

export const OptionListIconContainer = styled.div`
	flex: 1;
	width: 100%;
	// border: 1px solid red;

	svg {
		width: 30%;
		fill: #ffffff;
	}
`;

export const Label = styled.div`
  // font: 15px Lora, sans-serif;
  text-align: left; // Center text
  // margin-bottom: 20px; // Adjust spacing after venue
  // border:1px solid red;
  background-color:#ffffff;
  color:black;
  border-radius:2px;
  padding:2px 5px;
  // width:auto;

`;


export const OptionListContainer = styled.div`
  position: relative;
	width: 100%;
	max-width: 978px;
	margin: 0px auto;
	padding: 5px 0;
	// margin-bottom: 15px;
	// border:10px solid blue;
	height: 280px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
      gap: 10px;
	background-color: none;
  overflow: auto !important; // enable horizontal scrolling
  // margin-bottom:20px;
  

	position: sticky; // Add this line
	// top: 0; // And this line
	z-index: 999;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */


	// @media (max-width: 576px) {
	// 	margin: 0;
	// 	padding: 0px 0px 30px 0px;
	// }
`;

export const OptionList = styled.button`
position:relative;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:space-between;
	width: 300px;
	border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius:15px;
	height: 100%;
	background-color:#ffffff;
	color: rgba(0, 0, 0, 1);
	font-size: clamp(18px, 2vw, 2rem);
	min-width: 250px;
	padding: 10px 10px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;

	//Acts like an 'active' tab
	border: ${(props) =>
		props.$active
			? "3px solid rgba(255, 133, 89, 1)"
			: "2px solid rgba(0, 0, 0, 0.1)"};

	background-color:${(props) =>
		props.$active
			? "rgba(255, 133, 89, 0.3)"
			: "#ffffff"};

&.full{

	background-color:lightgrey;

}



&.full::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top right, transparent 50%, grey 50%);
  opacity: 0.5;
  box-sizing: border-box;
}


`;



export const DynamicallyChangeContent = styled.div`
  text-align: left; // Center text
  // margin-bottom: 20px; // Adjust spacing after venue
  // border:1px solid red;
  // background-color:rgba(0,0,0,0.5);
  color:black;
  // padding:2px 5px;
  width:100%;
      height: auto; // Set height to auto

    

   ul {
    list-style-type: disc; // Use disc for bullet points
    padding-left: 20px; // Add some padding to indent the list
      font-size:18px;
    
  }

`;

export const DynamicallyChangeContentContainer =styled.div`
display: flex;
overflow: hidden; /* Add this to hide the components that are off-screen */
width: 100%;
background-color:rgba(	255, 242, 237,1);
  border-radius:15px;
  	padding: 10px 10px;
    // border:1px solid red;
    // min-height:100px;
    height:100%;
      position: relative;
  margin-top:20px;
`


const SelectClass = ({ course, option1value , option2value, fee, deadline, noofclasses, setActiveCourse, activeCourse, program, availability,    handleOptionClick}) => {


const date = new Date(deadline);
const formattedDate = date.toLocaleDateString();

const handleSelectCourse = (course) => {
    const { details, ...courseWithoutDetails } = course;
    setActiveCourse(courseWithoutDetails);
    handleOptionClick(course)
};

  return(
<OptionList $active={activeCourse?.code === course.code} 
     onClick={availability === null || availability > 0 ? () => { handleSelectCourse(course);} : null}
       className={availability === 0 ? 'full' : ''}
>
<OptionListTopContainer>
						<Label>{course.code}</Label>
            <OptionListTitle>{option1value}</OptionListTitle>	
            <OptionListTitle>{option2value}</OptionListTitle>	
        </OptionListTopContainer>
        {/* <OptionListMiddleContainer>Enrolment deadline: </OptionListMiddleContainer>
           <OptionListMiddleContainer>{formattedDate} </OptionListMiddleContainer> */}
            <OptionListPrice>HK$ {fee}</OptionListPrice>
                  {availability === 0 && <div style={{color: 'red'}}>FULL</div>}

			</OptionList>
  )
};

export const LessonSelector = ({ activeCourse, setActiveCourse, courses, program, handleOptionClick }) => {

const [slideDirection, setSlideDirection] = useState('right');

  return (
    <>
 
      <OptionListContainer>
        {courses?.map((option, index) => (
          <SelectClass key={index} {...option}  course={option} setTab={() => {
      setSlideDirection(index > courses.indexOf(activeCourse) ? 'right' : 'left');
    }} 
    program={program}
      setActiveCourse={setActiveCourse}
      activeCourse={activeCourse} 
      handleOptionClick={handleOptionClick}
 />
            ))}


     </OptionListContainer>

   <CSSTransitionCSS/>


   {/* {activeCourse && ( */}

{/* //  <DynamicallyChangeContentContainer>
//   {courses?.map((option, index) => ( */}
{/* //       <CSSTransition */}
{/* //             key={index}
//             in={activeCourse?.code === option.code}
//             timeout={500}
//             classNames={`slide-${slideDirection}`}
//             unmountOnExit
//             appear // Add this prop
//           >
//  <DynamicallyChangeContent>
//   <OptionListTitle>Course details</OptionListTitle> */}
    {/* <ul>
    <li>Dates: </li>
    <li>Time: </li>
    <li>Total {option?.noofclasses} classes</li>
    <li>Capacity: {option?.capacity}</li>
  </ul> */}

    {/* <div dangerouslySetInnerHTML={{ __html: option?.details }} /> */}

  {/* <OptionListTitle>What's included</OptionListTitle> */}

      {/* </DynamicallyChangeContent>
        </CSSTransition>
        ))}
        </DynamicallyChangeContentContainer> */}



  {/* //  )} */}
   

</>
  );
}


export default LessonSelector;

