import React, { useState } from "react";
import { Children } from "react";
import styled from "styled-components";

// Styled checkbox container
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  // margin-top: 20px;
  // border: 1px solid red;
  width: 100%;
`;

// Styled label for better accessibility and design
const Label = styled.label`
  margin-left: 15px;
  font-size: 14px;
  // border: 1px solid red;
  flex: 4;
`;

// Styled input for the checkbox
const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  cursor: pointer;
  text-align: left;
  // border: 1px solid red;
`;

export const TextTemplate = {
  photoConsent: {
    en: "Consent For Photography: Participants of the MTR Mall Synthetic Ice Rink Pop Up Tour agree to authorize the organizing parties to use all videos and photos taken at the Tour",
    ch: "使用照片同意聲明:所有社區冰球計劃的參加者同意授權主辦及協辦機構使用所有活動照片及影片。",
  },
  disclaimerConsent: {
    en: "Disclaimer: By submitting this form, you agree to the terms and conditions of the Empire",
    ch: "免責聲明:提交此表格即表示您同意社區冰球計劃的條款和條件。",
  },
};

export const ConsentCheckbox = ({
  formData,
  setFormData,
  language,
  template,
  onClick,
  requireModalConsent,
  content,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const toggleCheckbox = (event) => {
    if (!requireModalConsent) {
      setIsChecked(!isChecked);
      const { name, checked } = event.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: checked,
      }));
    }
  };

  return (
    <>
      <CheckboxContainer>
        <Checkbox
          type="checkbox"
          id={template}
          checked={formData[template]}
          name={template}
          onChange={toggleCheckbox}
          onClick={(e) => {
            if (requireModalConsent) {
              onClick(e);
            }
          }}
        />
        <Label htmlFor={template}>{TextTemplate[template][language]}</Label>
      </CheckboxContainer>
    </>
  );
};
