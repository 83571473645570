import { createContext, useState, useEffect } from "react";
import axios from "axios";
import api from '../Api'; // adjust the path based on where api.js is located

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);


    // Function to load the user from the backend
    const getUser = async () => {
      setLoading(true);
        try {
            const res = await api.get('/classBooking/api/user/getUser',{ withCredentials: true,});
                console.log('Fetched user:', res.data); // Add this line

            setCurrentUser(res.data);
            setLoading(false); // Set loading to false after the user data is fetched

        } catch (error) {
          if (error.response.status === 401) {
             setCurrentUser(null);
        }
      }
        setLoading(false);
    };


  // Expose a refreshUser function that calls getUser
  const refreshUser = () => {
    getUser();
  };




// On mount, check the user's authentication status
      // On mount, check the user's authentication status
  useEffect(() => {
    getUser();

    // Set an interval to periodically check the user's authentication status
    const interval = setInterval(() => {
      getUser();
    }, 60000); // Check every 1 minute

    // Clear the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  // const login = async (inputs) => {
  //   const res = await axios.post("/adminGallery/api/auth/login", inputs);

  //   setCurrentUser(res.data);
  // };


  //   if (loading) {
  //   return <div>Loading...</div>; // Replace this with your actual loading component
  // }

  const logout = async () => {
    await api.post("/classBooking/api/auth/logout");
    setCurrentUser(null);
  };

  return (
    //can use this state & functions everywhere in the app
    <AuthContext.Provider value={{ currentUser, refreshUser, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
