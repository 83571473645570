//ClassDetails.js
import React, { useEffect, useState, useRef, useContext  } from "react";
import { useNavigate, useLocation} from 'react-router-dom';
import styled from "styled-components";
import ButtonMobile from "../../components/ButtonMobile/ButtonMobile";
import ButtonBack from "../../components/ButtonMobile/ButtonBack";
import Calender from "../../components/Calender/Calender";
import ButtonUploadPayment from "../../components/ButtonMobile/ButtonUploadPayment";
import { TitleHeader } from "../../globalStyles";
import Cookies from 'js-cookie';
import axios from "axios";
import { AuthContext } from "../../context/authContext";
import { HistoryContext } from '../../context/historyContext';
import MessageModal from "../../components/Modal/Modal.jsx";
import ButtonAddChild from "../../components/ButtonMobile/ButtonAddChild";
import {AddUserMemberForm} from "../../components/Form/Form";
import {ConsentCheckbox} from "../../components/Form/ConsentCheckbox";
import api from '../../Api'; // adjust the path based on where api.js is located



import {  LoadingOverlay,
  LoadingSpinner,
} from "../../globalStyles.js";



import {
  FormContainer,
  Input,
  FormFeedback
} from "../../pages/loginpages/LoginStyles";



export const ClassCard = ({programInfo, classInfo, orderDetails}) => {
  const navigate = useNavigate()

    const { currentUser, loading } = useContext(AuthContext);

 
        const [student, setStudent] = useState(null)
          const [contact, setContact] = useState(null);
const [errors, setErrors] = useState({});
const [userMember, setUserMember] = useState([]);

const [isFormInitialized, setIsFormInitialized] = useState(false);

useEffect(() => {
  const initializeStudent = () => {
      if (userMember?.length > 0) {
        const { first_name, last_name, dateofbirth, gender } = userMember[0];
        if (programInfo?.requireConsent) {
          setStudent({
            firstname: first_name,
            lastname: last_name,
            dob: dateofbirth,
            gender: gender,
            disclaimerConsent: ""
          });
        } else {
          setStudent({
            firstname: first_name,
            lastname: last_name,
            dob: dateofbirth,
            gender: gender
          });
        }
      } else {
        if (programInfo?.requireConsent) {
          setStudent({
            firstname: "",
            lastname: "",
            dob: "",
            gender: "",
            disclaimerConsent: ""
          });
        } else {
          setStudent({
            firstname: "",
            lastname: "",
            dob: "",
            gender: ""
          });
        }
      }
    };


  const initializeContact = () => {
      const initialContact = [...Array(8)].reduce((acc, _, index) => {
        acc[`mobile-${index}`] = '';
        return acc;
      }, { email: '', mobile:'' });

      if (programInfo?.requirePayment) {
        initialContact.payment = '';
      }

      setContact(initialContact);
    };

    const initializeForm = () => {
      initializeStudent();
      initializeContact();
      setIsFormInitialized(true);
    };

    initializeForm();


}, [programInfo,userMember]); // Run this effect when courseId changes



console.log(contact, student)
	// Regular expression for email validation
	const emailRegex = /\S+@\S+\.\S+/;
  const mobileRegex =/^\d{8}$/;


const validateFormData = () => {

  let newErrors = {};
  // Validate student data
  for (let key in student) {
    if (student[key] === "" || student[key] == null || student[key] == undefined || student[key] == false) {
      newErrors[key] = "This field is required";
    } else {

if (key === "dob"){
const dob = new Date(student.dob);
      const today = new Date();
      const minDate = new Date("1920-01-01");
        const year = dob.getFullYear();

if (isNaN(year)) {
   newErrors.dob = "Invalid date";
  } else if (year < 1920 || dob > new Date()) {
    newErrors.dob = "Date of birth must be between year 1920 and today";
  }
      }
    }
  }

  // Validate contact data
  for (let key in contact) {
    if (contact[key] === "" || contact[key] == null || contact[key] == undefined) {
      newErrors[key] = "This field is required";
    } else {
      if (key === "email" && !emailRegex.test(contact[key])) {
 newErrors["email"] = "Invalid email format";
  }

if(key === "mobile" && !mobileRegex.test(contact[key])){
newErrors["mobile"] = "Mobile number has to be in 8 digits long and only numbers";
}

}
  }
  setErrors(newErrors);
  console.log(errors)
  // If there are no errors, return true
  return Object.keys(newErrors).length === 0;
};




    const [tab, setTab] = useState(null);
      const [image, setImage] = useState(null);
      	const [modalOpen, setModalOpen] = useState(false);
        const [autoClose, setAutoClose] = useState(true);
	const [modalTemplate, setModalTemplate] = useState("");
    const [loadingCircle, setLoadingCircle] = useState(false);
  const [files, setFiles] = useState(null); //Handle Upload Photos
    const [uploadDir, setUploadDir] = useState(null);
        const [submitImage, setSubmitImage] = useState(null);


  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();

    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
          setSubmitImage(file);
    const reader = new FileReader();

    reader.onloadend = () => {
      // Here you can handle the preview of the image
      console.log(reader.result);
            setImage(reader.result);

    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };


useEffect(() => {
       let activeCourseFromCookie = Cookies.get('activeCourse');
  if (!loading && programInfo?.requireLogin && !currentUser) {
    navigate('/en/login'); // replace '/login' with your actual login route
  } else if (!activeCourseFromCookie){
 navigate(history); // r
  }
}, [programInfo, currentUser]);


// console.log(student)
// console.log(contact)


  const host = window.location.hostname;
const subdomain = host.split('.')[0];
const shopName = subdomain;
const { history, setHistory } = useContext(HistoryContext);


  useEffect(() => {
 const savedHistory = localStorage.getItem('history');
  if (savedHistory) {
    setHistory(JSON.parse(savedHistory));
  }

	

		const fetchData = async () => {
			try {
				const res = await api.get(
					`/classBooking/api/user/getUserMembers`,{
            withCredentials: true,
        }
				);
				setUserMember(res.data);
          setTab(res.data[0].id)
			} catch (err) {
				console.log(err);
			}
		};

		fetchData();
		// setResultChange("All");

		// const intervalId = setInterval(fetchData, 1000);

		// Clean up the interval when the component unmounts or when dependencies change
		// return () => {
		// 	clearInterval(intervalId);
		// };
	}, []);



  const handleChange = (event) => {
    const { name, value, files } = event.target;

    let updatedValue = value;

    if (name === "payment") {
        updatedValue = files[0]; // Store the entire file object
        console.log(files[0].name);
    }

    setContact((prevFormData) => ({
        ...prevFormData,
        [name]: updatedValue,
    }));
};


const openModalConsent = (template, e)=> {
   e.preventDefault(); // Prevent the checkbox from being checked
		setModalOpen(true);
		setModalTemplate(template);
    setAutoClose(false)
  }


const SubmitOrder = async () => {

 const isValid = validateFormData();

    if (isValid) {
    setLoadingCircle(true);

try {


const formData = new FormData();

if (programInfo.requirePayment) {
  formData.append('photos', submitImage); // 'photos' is the name of the field
}
  // Add other order details to the form data
  formData.append('orderDetails', JSON.stringify(orderDetails));
  formData.append('contact', JSON.stringify(contact));
  formData.append('student', JSON.stringify(student));
  formData.append('status', Number(!programInfo.requireConfirmBooking));

  const data = {
  orderDetails: JSON.stringify(orderDetails),
  contact: JSON.stringify(contact),
  student: JSON.stringify(student),
};

				const res = await api.post(
					`/classBooking/api/orders`,
				formData,{ withCredentials: true, headers: {
    'Content-Type': 'multipart/form-data',
  },});

        if (res.status === 200) {

  orderDetails.orderId = res.data.orderId;
  orderDetails.token = res.data.token;


if (currentUser){

	const res = await api.post(
					`/classBooking/api/user/addUserMember`,
					student,{   withCredentials: true,}
				);
}

// if (programInfo.requirePayment) {
//             // Upload the imag

//           const formData = new FormData();


//   formData.append('photos', submitImage); // 'image' is the name of the field
// //   // Submit the form data and the image
//   const response = await api.post(`/classBooking/api/orders/uploadReceipt?orderId=${res.data.orderId}`, formData, {   withCredentials: true,});

 
  
//     if (response.status === 200) {


// if (programInfo.requirePayment) {

const emailData = {
  recipientEmail: contact.email, // replace with actual recipient email
  orderDetails: orderDetails, // use the data you submitted before
  student: {
    firstname: student.firstname, 
    lastname: student.lastname, 
    dob: student.dob, 
    gender: student.gender
  },
  language:"en"
};

if(programInfo.requireConfirmBooking){
  // Send confirmation email out
  const sendOutEmail = await api.post(`/classBooking/api/orders/emailConfirmationEmail`, emailData, {   withCredentials: true,});

  } else {

 const sendTicket = await api.post(`/classBooking/api/orders/sendTicket`, emailData , {   withCredentials: true,});

  }
  // Send confirmation email out
  const emailOrderEmailNotice = await api.post(`/classBooking/api/orders/emailOrderEmailNotice`, emailData, {   withCredentials: true,});


  }


//     // Add student
//   const addStudent = await axios.post(`http://${shopName}.booking.dynevents.local:8089/classBooking/api/student/add`, emailData, {   withCredentials: true,});




        // If the order is submitted successfully, clear the activeCourse cookie
        Cookies.remove('activeCourse');

        setLoadingCircle(false);
				setModalOpen(true);
				setModalTemplate("success");
				setTimeout(() => {
if (programInfo.requireConfirmBooking){
			navigate(`/en/bookings`);

} else {
					navigate(`/en/order/${res.data.token}`);
}
				}, 3000);

      // }



			} catch (err) {
	
  setLoadingCircle(false);
				setModalOpen(true);
				setModalTemplate("fail");
				setTimeout(() => {
					  navigate(history);
				}, 3000);
			}
    }
}

  const handleMobileChange = (index, e) => {
    const value = e.target.value.replace(/[^0-9]/g, ''); // Only allow numbers
    setContact((prevFormData) => {
      // Combine the mobile input values into a single string
      const mobileArray = [...Array(8)].map((_, i) => prevFormData[`mobile-${i}`] || '');
      mobileArray[index] = value;
      const combinedMobile = mobileArray.join('');

      return {
        ...prevFormData,
        [`mobile-${index}`]: value,
        mobile: combinedMobile
      };
    });

    if (value && index < 7) {
      document.getElementById(`mobile-${index + 1}`).focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && !e.target.value && index > 0) {
      document.getElementById(`mobile-${index - 1}`).focus();
    }
  };


  return (
    <>
      <CardContent>
        <ButtonWrapper>
        <ButtonBack onClick={() => navigate(-1)}></ButtonBack>
        </ButtonWrapper>
        <TitleHeader>Complete booking</TitleHeader>

        <ClassInfoWrapper>

          <ClassTitleWrapper>
                      <Label> {classInfo?.code}</Label> 
          <ClassTitle>
        {programInfo?.name}
          </ClassTitle>
               </ClassTitleWrapper>

     <ClassDetailsStyled>
         <DetailValue> {classInfo?.
option1value
}</DetailValue>
 <DetailValue> {classInfo?.
option2value
}</DetailValue>
<DetailValue> Total {classInfo?.
noofclasses
} classes</DetailValue>

  </ClassDetailsStyled>
         
                       <ClassTitleWrapper>
      <OptionListPrice>
  {classInfo?.fee === "0" ? 'Free' : `HK$ ${classInfo?.fee}`}
</OptionListPrice>
              </ClassTitleWrapper>

        </ClassInfoWrapper>

<ClassInfoWrapper>
        <Subheader>Participant Info</Subheader>

     {programInfo?.requireLogin && currentUser ? (


userMember.length > 0 ? (

<OptionListContainer>
      {userMember?.map((info, index) => {
        const dateOfBirth = new Date(info.dateofbirth);
        const formattedDateOfBirth = dateOfBirth.toLocaleDateString();

        return (
        <OptionList $active={tab === info.id} onClick={() => { setStudent({ 
  firstname: info.first_name,
  lastname: info.last_name,
  dob: dateOfBirth,
  gender: info.gender
});

setTab(info.id);

}}>
            <OptionListTopContainer>
              <Label>ST{info.id}</Label>
              <OptionListTitle>{info.first_name} {info.last_name}</OptionListTitle>	
            </OptionListTopContainer>
            <OptionListMiddleContainer>Date of birth: </OptionListMiddleContainer>
            <OptionListMiddleContainer>{formattedDateOfBirth} </OptionListMiddleContainer>
            <OptionListMiddleContainer>{info.gender} </OptionListMiddleContainer>
          </OptionList>
        )
      })}
    </OptionListContainer>


) : (
 <AddUserMemberForm formData={student} setFormData={setStudent} withSubmitBtn={false} errors={errors} language={"en"}/>
    
)
      ) : ( 

   <AddUserMemberForm formData={student} setFormData={setStudent} withSubmitBtn={false} errors={errors} language={"en"}/>)}

        </ClassInfoWrapper>

   <ClassInfoWrapper>
        <Subheader>Contact Info</Subheader>

          <FormContainer>
                      {/* <Note>Require valid HK Mobile for WhatsApp Communication</Note>
    <Input
              required
              type="text"
              placeholder="Mobile Contact for WhatsApp confirmation"
              name="mobile"
              onChange={handleChange}
            /> */}


  <MobileInputContainer>
              <MobileInputTitle>
                Require valid HK Mobile for WhatsApp Communication. Hong Kong
                Mobile (+852)
              </MobileInputTitle>
              <MobileInputWrapper>
                {Array.from({ length: 8 }).map((_, index) => (
                  <ThinInput
                    required
                    key={index}
                    type="tel"
                    maxLength="1"
                    name={`mobile-${index}`}
                    value={contact ? contact[`mobile-${index}`] || "" : ""}
                    onChange={(e) => handleMobileChange(index, e)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    pattern="[0-9]*"
                    id={`mobile-${index}`}
                  />
                ))}
              </MobileInputWrapper>
            </MobileInputContainer>


               {errors.mobile && (
        <FormFeedback>{errors.mobile}</FormFeedback>
      )}

     <Note>Require valid Email for booking confirmation</Note>
              <Input
              required
              type="text"
              placeholder="Email"
              name="email"
              onChange={handleChange}
            />
              {errors.email && (
        <FormFeedback>{errors.email}</FormFeedback>
      )}

  {/* <Note>Kindly take a screenshot of the confirmation page for reference, also please note an SMS message reminder will be received a day prior your booking day. </Note> */}
    
{programInfo?.requireConsent ? (
<>
{/* <ConsentCheckbox formData={student} setFormData={setStudent} language={"en"} template={"photoConsent"}/> */}
 {/* {errors.photoConsent&& (
        <FormFeedback>{errors.photoConsent}</FormFeedback>
      )} */}
<ConsentCheckbox formData={student} setFormData={setStudent} language={"en"} template={"disclaimerConsent"} content={programInfo?.disclaimer_en} onClick={(e)=>openModalConsent("disclaimerConsent", e)}  requireModalConsent={true}/>
 {errors.disclaimerConsent&& (
        <FormFeedback>{errors.disclaimerConsent}</FormFeedback>
      )}

</>) : (null)  }

          </FormContainer>


      </ClassInfoWrapper>





{programInfo?.requirePayment ? (
      <ClassInfoWrapper>
        <Subheader>Payment Method</Subheader>
        <Note>Payment can be made by FPS or Bank Transfer</Note>
        <NoteHighlight><p>Bank Account: OCBC Bank 829-4414-26051</p>
        Empire Sports Limited (035)</NoteHighlight>
        <Note>Make sure the following information is clearly visible:</Note>
        <Note>*Your bank account number</Note>
        <Note>*Payment Date</Note>
        <Note>*Amount Paid</Note>
        <br />
           
 {errors.payment && (
        <FormFeedback>{errors.payment}</FormFeedback>)}

 <input
        type="file"
        accept="image/*"
         name="payment"
        onChange={(e) => {
    handleFileChange(e);
    handleChange(e);
}}
        style={{ display: 'none' }}
        ref={fileInputRef}
        id="file"
       
      />

        <ButtonUploadPayment
          status="uploadedMessage"
          onClick={handleButtonClick}
       >
            {/* <input type="file" accept="image/*" onChange={handleImageUpload} /> */}
            </ButtonUploadPayment>
            <ImageContainer>
              {image && (
        <img src={image} alt="Preview" style={{ height: '100px', width: '100px' }} />
      )}
              </ImageContainer>
               </ClassInfoWrapper>
) : (null)  } 
           

        <FooterWrapper>
          <ButtonMobile onClick={SubmitOrder}>CONFIRM BOOKING</ButtonMobile>
        </FooterWrapper>
      </CardContent>

      {modalOpen && (
				<MessageModal
					isOpen={modalOpen}
					template={modalTemplate}
          language={"en"}
					autoClose={autoClose}
          setModalOpen={setModalOpen}
          formData={student}
          setFormData={setStudent}
          content={programInfo?.disclaimer_en}
				/>
			)}
        {/*************************************** 
            // LOADING CIRCLE //
        ***************************************/}
            {loadingCircle && (
              <LoadingOverlay>
                <LoadingSpinner />
              </LoadingOverlay>
            )}
    </>
  );
};

export const ClassConfirmPayment = () => {
  

    const navigate = useNavigate()
    const location = useLocation();

const { history, setHistory } = useContext(HistoryContext);
  const [classInfo, setClassInfo] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [programInfo, setProgramInfo] = useState(null);

useEffect(() => {
    
  let activeCourse;
  const activeCourseCookie = Cookies.get('activeCourse');



if (activeCourseCookie) {
  activeCourse = JSON.parse(activeCourseCookie);
  setClassInfo(activeCourse?.course);

const program_code = activeCourse?.program;

	const fetchProgramData = async () => {
			try {
				const res = await api.get(
					`/classBooking/api/class/${program_code}`
				);

		
			setProgramInfo(res.data);
          

			} catch (err) {
				console.log(err);
			}
		};

		fetchProgramData();

  } else {
  navigate(history);
    }
},[]);


  useEffect(() => {
  if (classInfo && programInfo) {
const { code: courseCode, fee, option1value, option2value, id, option1value_chi, option2value_chi } = classInfo;
const order_details = {
  id,
  courseCode,
  fee,
  option1value,
  option2value,
  option1value_chi,
  option2value_chi,
  programName: programInfo?.name,
  shopId: programInfo?.shop_id,
  venue: programInfo?.venue,
  programNameChi: programInfo?.name_chi,
  venue_chi: programInfo?.venue_chi
};

setOrderDetails(order_details);
} 
  },[programInfo, classInfo]);
 

  return (
    <>
        <ClassCard classInfo={classInfo} orderDetails={orderDetails}  programInfo={programInfo}/>
    </>
  );
};
export default ClassConfirmPayment;

//STYLED-COMPONENTS:
export const CardContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 100px;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  top: 22px;
  left: 22px;
  z-index: 10;
`;

export const NoteTitle = styled.div`
  font: 800 32px Lora, sans-serif;
  text-align: center; // Center text
  margin-top: 20px;
  margin-bottom: 0px; // Adjust spacing after title
  color: #0c729e;
  font-style: italic;
`;

export const Note = styled.div`
  // font-weight: 800;
  font-size:15px;
  text-align: left; // Center text
  margin-top: 0px;
  margin-bottom: 0px; // Adjust spacing after title
  color: #0c729e;
width:100%;
  
`;

export const NoteHighlight = styled.div`
 font-weight: 800;
  font-size:15px;
  text-align: center; // Center text
  margin-top: 0px;
  margin-bottom: 0px; // Adjust spacing after title
  color: #0c729e;
  border:3px solid orange;
  padding:5px;
  width:100%;
`;




export const ClassInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; //CENTER THE CONTENT TO MAKE UPLOAD COMPONENT IN THE MIDDLE
  background-color: #ffffff;
  text-align:left;
  width: 100%;
  padding: 10px 10px;
  // border-radius: 0 0 20px 20px;
  border-bottom: 15px solid lightgrey;
`;


export const ClassTitleWrapper = styled.div`
width:100%;
text-align:left;

`

export const ClassTitle = styled.h1`
  // border: solid black 1px;
  // background-color: #fff;
  // max-width: 400px;
  height: auto;
  font: 700 18px Poppins, sans-serif;
  text-align: left; // Center text
  margin: 10px 0;
  width:100%;
  // padding: 0 20px;
`;

export const ClassDetailsStyled = styled.div`
  // display: grid;
  // grid-template-columns: 1fr 3fr; // Defines two columns: 1st column is 1/4th, 2nd column is 3/4ths of the total width
  // gap: 10px; // Gap between grid items
  width: 100%; //Makes the grid 80% of the width of the parent container
  padding-bottom: 10px;
`;

export const DetailItem = styled.div`
  display: contents; // Makes the container behave like a direct child of the grid container
`;

// export const DetailLabel = styled.span`
//   color: var(--Text-Muted, gray);
//   font-family: Poppins, sans-serif;
//   font-size: 14px;
//   font-weight: 600;
//   color: #eee;
// `;

export const DetailValue = styled.div`
  font-family: Lora, sans-serif;
  width:100%;
  color: black;
  font-weight: 500;
  font-size: 16px;
  // text-decoration: underline;
  font-style: italic;
`;

const FooterWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 12px 12px 0 0;
  background-color: var(--color-white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 11px;
  gap: 20px;
  z-index: 1000;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); // Very thin drop shadow
  @media (min-width: 500px) {
    width: 500px; /* Apply max-width constraint */
    left: calc((100vw - 500px) / 2);
    right: calc((100vw - 500px) / 2);
  }
`;

export const InputForm = styled.form`
  /* border: solid red 3px; */
  padding: 0px 20px 0px 20px;
  margin: 0;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
`;

export const InputBoxWrapper = styled.div`
  align-self: stretch;
  border-radius: var(--br-12xl-5);
  background-color: var(--color-whitesmoke);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px var(--padding-mini) 0px var(--padding-base);
`;

export const InputBox = styled.input`
  height: 63px;
  flex: 1;
  position: relative;
  font-size: var(--font-size-lg);
  font-family: var(--font-poppins);
  color: var(--color-gray);
  text-align: left;
  display: flex;
  align-items: flex-start;
  z-index: 1;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0 var(--padding-mini);
  border-radius: var(--br-12xl-5);
  &:focus {
    outline: none;
  }
`;


export const Label = styled.div`
  font: 15px Lora, sans-serif;
  text-align: center; // Center text
  // margin-bottom: 20px; // Adjust spacing after venue
  // border:1px solid red;
  background-color:lightgrey;
  color:black;
  border-radius:2px;
  padding:2px 5px;
 width:25%;

`;

export const OptionListPrice = styled.div`
	// width: 100%;
	// margin: 10px auto;
  text-align:left;
  font-size:20px;
  font-weight:800;
`;


export const Subheader = styled.div`
  font-weight: 700;
  font-size: 20px;
  // text-align: left; // Center text
  margin-top: 10px; // Adjust spacing after venue
  margin-bottom:20px;
  // display:flex;
  // align-items:center;
  // justify-content:flex-start;
  border-left:10px solid rgb(0,0,139);
  width:100%;
  color:black;
  padding-left:20px;
  

`;


export const OptionListContainer = styled.div`
  position: relative;
	width: 100%;
	max-width: 978px;
	margin: 0px auto;
	padding: 5px 0;
	// margin-bottom: 15px;
	// border:10px solid blue;
	height: 220px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
      gap: 10px;
	background-color: none;
  overflow: auto !important; // enable horizontal scrolling
  // margin-bottom:20px;
  
  

	position: sticky; // Add this line
	// top: 0; // And this line
	z-index: 999;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */


	// @media (max-width: 576px) {
	// 	margin: 0;
	// 	padding: 0px 0px 30px 0px;
	// }
`;

export const OptionList = styled.button`
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
	// width: 300px;
	border: 2px solid rgba(0, 0, 0, 0.1);
  width: 200px;
  height: 200px;
  border-radius: 50%;
	// height: 100%;
	background-color:#ffffff;
	color: rgba(0, 0, 0, 1);
	font-size: clamp(18px, 2vw, 2rem);
	min-width: 200px;
	padding: 10px 10px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;

	//Acts like an 'active' tab
	border: ${(props) =>
		props.$active
			? "3px solid rgba(255, 133, 89, 1)"
			: "2px solid rgba(0, 0, 0, 0.1)"};

	background-color:${(props) =>
		props.$active
			? "rgba(255, 133, 89, 0.3)"
			: "#ffffff"};

`;



export const DynamicallyChangeContent = styled.div`
  text-align: left; // Center text
  // margin-bottom: 20px; // Adjust spacing after venue
  // border:1px solid red;
  // background-color:rgba(0,0,0,0.5);
  color:black;
  // padding:2px 5px;
  width:100%;
    height:100%;
    position:absolute;
    

   ul {
    list-style-type: disc; // Use disc for bullet points
    padding-left: 20px; // Add some padding to indent the list
      font-size:18px;
    
  }

`;

export const DynamicallyChangeContentContainer =styled.div`
display: flex;
overflow: hidden; /* Add this to hide the components that are off-screen */
width: 100%;
background-color:rgba(	255, 242, 237,1);
  border-radius:15px;
  	padding: 10px 10px;
    // border:1px solid red;
    // min-height:100px;
    height:200px;
      position: relative;
  margin-top:20px;


`

export const OptionListTopContainer = styled.div`
	// flex: 2;
	display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:flex-start;
	// border: 1px solid blue;
`;

export const OptionListMiddleContainer = styled.div`
color:grey;
font-size:15px;
text-align:left;
	// border: 1px solid blue;
`;

export const OptionListIconContainer = styled.div`
	flex: 1;
	width: 100%;
	// border: 1px solid red;

	svg {
		width: 30%;
		fill: #ffffff;
	}
`;

export const OptionListTitle = styled.div`
	width: 100%;
	margin: 10px auto;
  text-align:left;
  font-size:20px;
  font-weight:600;
`;


export const ImageContainer = styled.div`
	width: 50%;
	margin: 10px auto;

img{

width:100%;

}
`;

// ***RICK CHU 2021-09-29 for Mobile Input
export const MobileInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const MobileInputTitle = styled.div`
  text-align: center;
  font-size: clamp(1.8rem, 2vw, 2rem);
  color: #6b7180;
  margin-bottom: 1rem;
  width: 100%;
`;

export const MobileInputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
  gap: 6px;
`;


export const ThinInput = styled.input`
  width: calc(100% / 8);
  min-width: 30px;
  height: 60px;
  text-align: center;
  font-size: 1.8rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;

  &:focus {
    border-color: #77dffb;
  }

  @media (max-width: 400px) {
    width: 12%;
    min-width: 25px;
    height: 48px;
    font-size: 1.6rem;
  }
`;
